const AWS_REGIONS = [
  { 'id': 'us-east-2', 'label': 'US East (Ohio)', },
  { 'id': 'us-east-1', 'label': 'US East (N. Virginia)', },
  { 'id': 'us-west-1', 'label': 'US West (N. California)', },
  { 'id': 'us-west-2', 'label': 'US West (Oregon)', },
  { 'id': 'us-gov-east-1', 'label': 'AWS GovCloud (US-East)', },
  { 'id': 'us-gov-west-1', 'label': 'AWS GovCloud (US-West)', },
  { 'id': 'ca-central-1', 'label': 'Canada (Central)', },
  { 'id': 'ca-west-1', 'label': 'Canada West (Calgary)', },
  { 'id': 'af-south-1', 'label': 'Africa (Cape Town)', },
  { 'id': 'ap-east-1', 'label': 'Asia Pacific (Hong Kong)', },
  { 'id': 'ap-south-2', 'label': 'Asia Pacific (Hyderabad)', },
  { 'id': 'ap-southeast-3', 'label': 'Asia Pacific (Jakarta)', },
  { 'id': 'ap-southeast-4', 'label': 'Asia Pacific (Melbourne)', },
  { 'id': 'ap-south-1', 'label': 'Asia Pacific (Mumbai)', },
  { 'id': 'ap-northeast-3', 'label': 'Asia Pacific (Osaka)', },
  { 'id': 'ap-northeast-2', 'label': 'Asia Pacific (Seoul)', },
  { 'id': 'ap-southeast-1', 'label': 'Asia Pacific (Singapore)', },
  { 'id': 'ap-southeast-2', 'label': 'Asia Pacific (Sydney)', },
  { 'id': 'ap-northeast-1', 'label': 'Asia Pacific (Tokyo)', },
  { 'id': 'eu-central-1', 'label': 'Europe (Frankfurt)', },
  { 'id': 'eu-west-1', 'label': 'Europe (Ireland)', },
  { 'id': 'eu-west-2', 'label': 'Europe (London)', },
  { 'id': 'eu-south-1', 'label': 'Europe (Milan)', },
  { 'id': 'eu-west-3', 'label': 'Europe (Paris)', },
  { 'id': 'eu-south-2', 'label': 'Europe (Spain)', },
  { 'id': 'eu-north-1', 'label': 'Europe (Stockholm)', },
  { 'id': 'eu-central-2', 'label': 'Europe (Zurich)', },
  { 'id': 'il-central-1', 'label': 'Israel (Tel Aviv)', },
  { 'id': 'me-south-1', 'label': 'Middle East (Bahrain)', },
  { 'id': 'me-central-1', 'label': 'Middle East (UAE)', },
  { 'id': 'sa-east-1', 'label': 'South America (São Paulo)', },
]
const AppFields = [
    { id: 'anniversary', label: 'Anniversary' },
    { id: 'business_name', label: 'Business Name' },
    { id: 'birthday', label: 'DOB' },
    { id: 'email', label: 'Email' },
    { id: 'full_name', label: 'Full Name' },
    { id: 'first_name', label: 'First Name' },
    { id: 'last_name', label: 'Last Name' },
    { id: 'phone', label: 'Phone' },
]
export {
    AWS_REGIONS,
    AppFields,
}