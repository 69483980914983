<template>
  <div v-if="obj" class="integration-sendgrid">
    <div class="row">
      <div class="col-md-6">
        <div v-if="isEdit" class="row mt-4">
          <div class="col-md-12 d-flex align-items-center">
            <div style="flex: auto">
              <div class="d-block">
                <TextInput v-if="!obj.editApiKey" v-model="obj.key_encrypted" name="Key" label="Key" :disabled="true"
                  rules="required" sublabel="(*)" />
                <TextInput v-else v-model="obj.key_new" name="Key" label="Key" rules="required"
                  sublabel="(*)" />
              </div>
              <div class="d-block">
                <TextInput v-if="!obj.editApiKey" v-model="obj.api_key" name="Secret" label="Secret" :disabled="true"
                  rules="required" sublabel="(*)" />
                <TextInput v-else v-model="obj.api_key_new" name="Secret" label="Secret" rules="required"
                  sublabel="(*)" />
              </div>
              <div class="d-block">
                <TextInput v-if="!obj.editApiKey" v-model="obj.region" name="Secret" label="Secret" :disabled="true"
                  rules="required" sublabel="(*)" />
                <div v-else class="d-block">
                  <label>Region</label>
                  <multiselect
                    v-model="obj.options.region"
                    :options="awsRegionList"
                    placeholder="Select Region"
                    label="label"
                    :multiple="false"
                    track-by="id"
                    :allow-empty="false"
                    :show-labels="false"
                  ></multiselect>
                </div>
              </div>
              <div class="mt-4">
                <TextInput v-if="!obj.editApiKey" v-model="obj.options.from_email" type="email" name="fromEmail" label="From Email" :disabled="true"
                  sublabel="(*)" />
                <TextInput v-else v-model="obj.from_email_new" type="email" name="fromEmail" label="From Email" sublabel="(*)" />
              </div>
            </div>
            <span class="btn btn-primary btn-sm ml-2" @click="cancelEditApiKey">{{ obj.editApiKey ? 'Cancel' : 'Change'
              }}</span>
          </div>
        </div>
        <div v-else class="mt-4">
          <TextInput v-model="obj.options.key" name="Key" label="Key" sublabel="(*)" rules="required" />
          <TextInput v-model="obj.api_key" name="Secret" label="Secret" sublabel="(*)" rules="required" />
          <div class="d-block">
            <label>Region</label>
            <multiselect
              v-model="obj.options.region"
              :options="awsRegionList"
              placeholder="Select Region"
              label="label"
              :multiple="false"
              track-by="id"
              :allow-empty="false"
              :show-labels="false"
            ></multiselect>
          </div>
          <div class="mt-4">
            <TextInput v-model="obj.options.from_email" type="email" name="fromEmail" label="From Email" sublabel="(*)" rules="required" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { AWS_REGIONS } from '@src/constants'

export default {
  components: {
    Multiselect,
  },
  props: {
    obj: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      awsRegionList: AWS_REGIONS,
    }
  },

  computed: {
  },

  mounted() {
    if (!this.obj.options.region)
      this.obj.options.region = this.awsRegionList.find(item => item.id === 'us-east-1')
  },

  methods: {
    cancelEditApiKey() {
      this.obj.editApiKey = !this.obj.editApiKey
    },
  },
}
</script>

<style lang="scss" scoped></style>